import { reqWXBind,reqGetUserInfo } from '@/network/api'
import {
  saveToken,
  saveLoginStatus,
  saveUserInfo,
  saveOpenId,
  removeToken,
  removeUserInfo,
  removeLoginStatus,
  loadLoginStatus,
  loadToken,
  loadUserInfo,
  loadOpenId,
  saveWXBind,
  loadWXBind
} from '../cache'
const state = {
  loginStatus: loadLoginStatus(), // 登录状态
  token: loadToken(), // token
  userInfo: loadUserInfo(), // 用户登录信息
  openid: loadOpenId(), // openid
  wxbind:loadWXBind(), // 微信绑定
  address:{id:0} // 选中地址
}

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_OPENID: (state, openid) => {
    state.openid = openid;
  },
  SET_WX_BIND:(state, bool) => {
    state.wxbind = bool;
  },
  SET_ADDRESS:(state,info)=>{
    state.address = info;
  }
}

const actions = {
  // 设置选中的地址
  setAddress({ commit }, info) {
    commit('SET_ADDRESS', info)
  },
  // 设置token
  setToken({ commit }, token) {
    commit('SET_TOKEN', saveToken(token))
  },
  // 设置用户名
  setUserInfo({ commit }, info) {
    commit('SET_USERINFO', saveUserInfo(info))
  },
  saveOpenId({ commit }, openid) {
    commit('SET_OPENID', saveOpenId(openid))
  },
  saveWXBind({ commit }, bool) {
    commit('SET_WX_BIND', saveWXBind(bool))
  },
  // 登录相关，通过code获取token和用户信息，用户根据自己的需求对接后台
  loginWechatAuth({ commit }, code) {
    const data = {
      code: code
    }
    return new Promise((resolve, reject) => {
      reqWXBind(data).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) {
          return resolve();
        };
        if(backdata.token){
          commit('SET_TOKEN', saveToken(backdata.token));
          reqGetUserInfo({
            user_id: 0
          }).then(async res => {
            const { msg, backdata, code } = res;
            if (code != 0) return;
            commit('SET_USERINFO', saveUserInfo(backdata.userinfo));
          });
        }
        else{
          commit('SET_OPENID', saveOpenId(backdata.openid))
        }

        if (msg == "微信未绑定") {
          commit('SET_WX_BIND', saveWXBind(false));
        }
        else{
          commit('SET_WX_BIND', saveWXBind(true));
        }
        // commit('SET_TOKEN', saveToken(res.data.token))
        resolve(backdata)
      }).catch(error => {
        reject(error)
      })
      // resolve()
      // loginByCode(data)
      // .then(res => {
      //     // 存用户信息，token
      //     console.log(res);

      //     commit('SET_USERINFO', saveUserInfo(res.data.user))
      //     commit('SET_TOKEN', saveToken(res.data.token))
      //     resolve(res)
      //   })
      //   .catch(error => {
      //     reject(error)
      //   })
    })
  },
  // 设置状态
  setLoginStatus({ commit }, query) {
    if (query === 0 || query === 1) {
      // 上线打开注释，本地调试注释掉，保持信息最新
      removeToken()
      removeUserInfo()
    }
    // 设置不同的登录状态
    commit('SET_LOGIN_STATUS', saveLoginStatus(query))
  },
  // 登出
  fedLogOut({ commit }) {
    // 删除token，用户信息，登陆状态
    commit('SET_TOKEN', "");
    commit('SET_USERINFO', null);
    // commit('SET_LOGIN_STATUS', 0);
    
    removeToken()
    removeUserInfo()
    // removeLoginStatus()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
