import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const Home = () => import("views/home/home.vue")
const Auction = () => import("views/auction/auction.vue")
const Mine = () => import("views/mine/mine.vue")
const Rule = () => import("views/mine/rule.vue")
const Login = () => import("views/login/login.vue")
const Register = () => import("views/login/register.vue")
const Forget = () => import("views/login/forget.vue")
const Earnest = () => import("views/mine/earnest.vue")
const Pay = () => import("views/mine/pay.vue")
const Statement = () => import("views/mine/statement.vue")
const Address = () => import("views/mine/address.vue")
const Order = () => import("views/mine/order.vue")
const Notice = () => import("views/home/notice.vue")
const Detail = () => import("views/detail/detail.vue")
const Record = () => import("views/detail/record.vue")
const Logistics = () => import("views/mine/logistics.vue")
const Cart = () => import("views/mine/cart.vue")


Vue.use(Router)

let routes = [
  {
    path: '/',
    name: "home",
    component: Home,
    meta: { keepAlive: true }
  },
  {
    path: '/index',
    redirect: "/"
  },
  {
    path: '/home',
    redirect: "/"
  },
  {
    path: '/notice',
    name: "notice",
    component: Notice
  },
  {
    path: '/detail',
    name: "detail",
    component: Detail
  },
  {
    path: '/detail/record',
    name: "record",
    component: Record
  },
  {
    path: '/mine',
    name: "mine",
    component: Mine
  },
  {
    path: '/mine/order',
    name: "order",
    component: Order
  },
  {
    path: '/address',
    name: "address",
    component: Address
  },
  {
    path: '/statement',
    name: "statement",
    component: Statement
  },
  {
    path: '/earnest',
    name: "earnest",
    component: Earnest
  },
  {
    path: '/pay',
    name: "pay",
    component: Pay
  },
  {
    path: '/login',
    name: "login",
    component: Login
  },
  {
    path: '/register',
    name: "register",
    component: Register
  },
  {
    path: '/forget',
    name: "forget",
    component: Forget
  },
  {
    path: "/auction",
    name: "auction",
    component: Auction,
    // meta:{keepAlive:true}
  },
  {
    path: '/rule',
    name: "rule",
    component: Rule
  },
  {
    path: '/logistics',
    name: "logistics",
    component: Logistics
  },
  {
    path: '/cart',
    name: "cart",
    component: Cart
  },
  {
    path: '*',
    redirect: "/"
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
