import axios from 'axios';
import { Indicator } from 'mint-ui';
import { BASEURL } from '../config';
import store from '@/store'

axios.defaults.baseURL = '/api'

// console.log(process.env)

function startLoading() {
    Indicator.open();
}

function endLoading() {
    Indicator.close();
}

const defaultOptions = {
    method: 'GET',
    data:{},
    url: "",
    headers:{
        "Requested-With":"XMLHttpRequest",
        "Token":""
    },
    isloading: true
}


const request = (options, isloading = true) => {
    const params = Object.assign({}, defaultOptions, options);

    const instance = axios.create({
        baseURL: BASEURL, //配置请求服务器路径
        timeout: 10000, //超时
    })

    instance.interceptors.request.use(options => {
        if (isloading) startLoading();
        options.headers.Token = store.getters.token || "";
        return options;
    })

    instance.interceptors.response.use(res => {
        if (isloading) endLoading();
        return res.data;
    }, err => {
        if (isloading) endLoading();
        console.error(err)
    })

    return instance(params);
}

export default request;