import router from "@/router";
import store from "@/store";
// import wechatAuth from '@/plugins/wechatAuth'
import { APPID, DOMAINURL, TITLE as title } from "@/config/index";
import { isWX, getQueryString, processUrl } from "@/plugins/untils";
import { reqGetMqtt, reqWXLogin } from "@/network/api";

let mreferrer = getQueryString("mreferrer");
if(mreferrer){
  store.dispatch("other/setMreferrer", mreferrer);
}

// 设置APPID
// wechatAuth.setAppId(APPID)
// console.log(JSON.stringify(store.getters))
const whiteList = ["login", "register", "forget", "rule"];
router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.name) !== -1) {
    // 在白名单，直接进入
    return next();
  }
  let status = 0;
  // console.log(store.getters);

  if (!isWX()) {
    status = 2;
  } else {
    status = await store.getters.loginStatus;
    if (getQueryString("code")) {
      status = 1;
    }
  }
  // 检查mqtt
  // const { mqtt } = store.getters;
  // if (!mqtt) {
    let mqtt_option = await reqGetMqtt(
      {
        user_id: 0,
      },
      false
    );
    await store.dispatch("other/setMQTT", mqtt_option.backdata.mqtt);
    console.log("new mqtt获取成功");
  // }

  switch (Number(status)) {
    case 0:
      // 获取跳转地址
      // wechatAuth.redirect_uri = processUrl()
      const res = await reqWXLogin({ backurl: processUrl() });
      await store.dispatch("user/setLoginStatus", 1);
      window.location.href = res.backdata.loginurl;
      // window.location.href = wechatAuth.authUrl
      break;
    case 1:
      try {
        // wechatAuth.returnFromWechat(window.location.href)
        // const code = wechatAuth.code
        const code = getQueryString("code") || "";
        // 通过code换取token
        await store.dispatch("user/loginWechatAuth", code);
        await store.dispatch("user/setLoginStatus", 2);
        next();
      } catch (err) {
        await store.dispatch("user/setLoginStatus", 0);
        next("/");
      }
      break;
    case 2:
      const { token, loginStatus, userInfo } = store.getters;
      const arr = [
        "mine",
        "statement",
        "earnest",
        "recharge",
        "address",
        "order",
        "notice",
        "auction",
        "pay",
        "logistics",
        "cart"
      ];
      await store.dispatch("other/setHistoryCount");
      try {
        const { id } = userInfo;
        if (arr.includes(to.name) && !id) {
          next({ name: "login" });
        }
      } catch (error) {
        if (to.name == "home") {
          next();
        } else {
          next({ name: "login" });
        }
      }

      document.title = title;
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      next();
      break;
    default:
      break;
  }
});
