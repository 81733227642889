import {
  saveMqttOption,
  loadMqttOption
} from '../cache'

const state = {
  mqtt: loadMqttOption(),
  mreferrer:"",
  mqttList:[],
  historyCount:0
}

const mutations = {
  SET_MQTT: (state, option) => {
    state.mqtt = option;
  },
  SET_MREFERRER: (state, mreferrer) => {
    state.mreferrer = mreferrer;
  },
  PUSH_MQTT_LIST:(state,item)=>{
    state.mqttList.push(item);
  },
  CLEAR_MQTT_LIST:(state)=>{
    state.mqttList.length = 0;
  },
  SET_HISTORY_COUNT:(state)=>{
    state.historyCount++;
  }
};

const actions = {
  // 设置MQTT
  setMQTT({ commit }, option) {
    commit("SET_MQTT", saveMqttOption(option));
  },
  // 设置来源
  setMreferrer({ commit }, option) {
    commit("SET_MREFERRER", option);
  },
  pushMqttList({ commit }, option){
    commit("PUSH_MQTT_LIST", option);
  },
  clearMqttList({ commit }){
    commit("CLEAR_MQTT_LIST");
  },
  setHistoryCount({commit}){
    commit("SET_HISTORY_COUNT");
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
