import qs from "qs";
import request from "./request.js";
import { BASEURL } from "../config";

/**
 * 登录接口请求token与userinfo
 * @param params {code: code}
 */
export const loginByCode = (params, isloading) => {
  return request(
    {
      url: "/wechat/auth2",
      method: "post",
      data: qs.stringify(params),
    },
    isloading
  );
};

// 注册
export const reqRegister = (params, isloading) =>
  request(
    {
      url: "api.php?c=user&a=reg",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 登录
export const reqLogin = (params, isloading) =>
  request(
    {
      url: "api.php?c=user&a=login",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 拍卖规则请求
export const reqRule = (params, isloading) =>
  request(
    {
      url: "api.php?c=help&a=show&id=1",
      method: "GET",
      data: qs.stringify(params),
    },
    isloading
  );

// 拍卖规则链接
export const reqRulePath = BASEURL + "index.php?c=help&a=show&id=1";

// 获取用户信息
export const reqGetUserInfo = (params, isloading) =>
  request(
    {
      url: "api.php?c=user&a=getUserInfo",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 发送验证码
export const reqSendCode = (params, isloading) =>
  request(
    {
      url: "api.php?c=api&a=sendsms",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 找回密码
export const reqGetPwd = (params, isloading) =>
  request(
    {
      url: "api.php?c=user&a=findPass",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 首页类型列表
export const reqHomeTypeList = (params, isloading) =>
  request(
    {
      url: "api.php?c=main&a=getTypeList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取拍场列表
export const reqHomeClassList = (params, isloading) =>
  request(
    {
      url: "api.php?c=main&a=getClassList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取图书列表
export const reqHomeBookList = (params, isloading) =>
  request(
    {
      url: "api.php?c=main&a=getBookList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取产品详情
export const reqProInfo = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=getInfo",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 设置收藏
export const reqSetLike = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=setLike",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取浏览列表
export const reqLookList = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=getViewList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取出价记录
export const reqBuyList = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=getBuyList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取更多拍品
export const reqMoreList = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=getMoreList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 立即出价
export const reqImmedPrice = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=setPrice",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 设置订阅通知
export const reqSetNotice = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=setNotice",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 设置委托出价
export const reqSetWeituo = (params, isloading) =>
  request(
    {
      url: "api.php?c=product&a=setWeituo",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取资金流水
export const reqGetOrderLog = (params, isloading) =>
  request(
    {
      url: "api.php?c=order&a=getOrderLog",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取用户中心数据
export const reqGetUserCenter = (params, isloading) =>
  request(
    {
      url: "api.php?c=user&a=getUserData",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取所有地址
export const reqGetAllAddress = (params, isloading) =>
  request(
    {
      url: "api.php?c=address&a=getAddsList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 设置默认地址
export const reqSetAddressDefault = (params, isloading) =>
  request(
    {
      url: "api.php?c=address&a=setDefault",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 删除地址
export const reqDelAddress = (params, isloading) =>
  request(
    {
      url: "api.php?c=address&a=delAdds",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取地址
export const reqGetAddress = (params, isloading) =>
  request(
    {
      url: "api.php?c=address&a=getAdds",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 添加/编辑地址
export const reqEditAddress = (params, isloading) =>
  request(
    {
      url: "api.php?c=address&a=setAdds",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取mqtt参数
export const reqGetMqtt = (params, isloading) =>
  request(
    {
      url: "api.php?c=api&a=getMqtt",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取上新通知
export const reqGetNewNotice = (params, isloading) =>
  request(
    {
      url: "api.php?c=notice&a=getNewNotice",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 设置上新通知
export const reqSetNewNotice = (params, isloading) =>
  request(
    {
      url: "api.php?c=notice&a=setNewNotice",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取竞拍
export const reqGetOrderList = (params, isloading) =>
  request(
    {
      url: "api.php?c=order&a=getOrderList",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 获取订单
export const reqGetMyOrder = (params, isloading) =>
  request(
    {
      url: "api.php?c=order&a=getMyOrder",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 下单
export const reqGetToBuy = (params, isloading) =>
  request(
    {
      url: "api.php?c=pay&a=toBuy",
      method: "POST",
      data: qs.stringify(params),
    },
    isloading
  );

// 支付
export const reqGetToPay = (params, isloading) =>
request(
  {
    url: "api.php?c=pay&a=toPay",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);

// 微信登录
export const reqWXLogin = (params, isloading) =>
request(
  {
    url: "api.php?c=user&a=wxLoginmp",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);

// 微信登录绑定
export const reqWXBind = (params, isloading) =>
request(
  {
    url: "api.php?c=user&a=wxLoginmpBind",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);

// 确认收货
export const reqSetOrderOk = (params, isloading) =>
         request(
           {
             url: "api.php?c=order&a=setOrderOk",
             method: "POST",
             data: qs.stringify(params),
           },
           isloading
         );

// 查询物流
export const reqGetExpress = (params, isloading) =>
         request(
           {
             url: "api.php?c=order&a=getExpress",
             method: "POST",
             data: qs.stringify(params),
           },
           isloading
         );

// 获取订单信息
export const reqGetPayInfo = (params, isloading) =>
         request(
           {
             url: "api.php?c=pay&a=getPayInfo",
             method: "POST",
             data: qs.stringify(params),
           },
           isloading
         );

// 获取购物车信息
export const reqGetCartInfo = (params, isloading) =>
request(
  {
    url: "api.php?c=cart&a=getInfo",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);


// 加入购物车
export const reqAddCart = (params, isloading) =>
request(
  {
    url: "api.php?c=cart&a=addCart",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);

// 减少购物车
export const reqDelCart = (params, isloading) =>
request(
  {
    url: "api.php?c=cart&a=delCart",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);

// 购物车结算
export const reqSetCart = (params, isloading) =>
request(
  {
    url: "api.php?c=cart&a=setBuy",
    method: "POST",
    data: qs.stringify(params),
  },
  isloading
);


