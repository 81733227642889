import cookies from 'js-cookie'
import storage from 'good-storage'
const LoginStatusKey = 'Login-Status' // 登录态 0未授权未登录 1授权未登录 2 登陆成功
const TokenKey = 'Access-Token' // token
const UserInfoKey = 'User-Info' // 用户信息 {} {...}
const MqttOption = 'MQTT-Option'
const OPENID = "User-OpenId";
const WXBIND = "WX-Bind";

// 获取微信绑定状态
export function loadWXBind() {
  return storage.get(WXBIND, false)
}
// 保存微信绑定状态
export function saveWXBind(bool) {
  storage.set(WXBIND, bool)
  return bool;
}

// 获取登录状态
export function loadLoginStatus() {
  return storage.get(LoginStatusKey,0)
}
// 保持登录状态
export function saveLoginStatus(status) {
  storage.set(LoginStatusKey, status)
  // cookies.set(LoginStatusKey, status, {expires: 7})
  return status
}
// 删除登录状态
export function removeLoginStatus() {
  storage.remove(LoginStatusKey)
  return ''
}

// 获取token
export function loadToken() {
  return storage.get(TokenKey, '')
}

// 保存token
export function saveToken(token) {
  storage.set(TokenKey, token)
  return token
}

// 删除token
export function removeToken() {
  storage.remove(TokenKey)
  return ''
}
// 获取用户信息
export function loadUserInfo() {
  return storage.get(UserInfoKey, {})
}
// 保存用户信息
export function saveUserInfo(userInfo) {
  storage.set(UserInfoKey, userInfo)
  return userInfo
}
// 删除用户信息
export function removeUserInfo() {
  storage.remove(UserInfoKey)
  return {}
}

// 设置mqtt参数
export function saveMqttOption(option) {
  storage.set(MqttOption, option);
  return option;
}

// 获取mqtt参数
export function loadMqttOption() {
  return storage.get(MqttOption, null);
}

// 设置openid
export function saveOpenId(id) {
  storage.set(OPENID, id)
  return id;
}

// 获取openid
export function loadOpenId() {
  return storage.get(OPENID, "");
}

