const getters = {
  // user
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  loginStatus: (state) => state.user.loginStatus,
  mqtt: (state) => state.other.mqtt,
  openid: (state) => state.user.openid,
  wxbind: (state) => state.user.wxbind,
  mreferrer: (state) => state.other.mreferrer,
  address:(state) => state.user.address,
  mqttList:(state) => state.other.mqttList,
  historyCount:(state)=>state.other.historyCount
};
export default getters
