<template>
  <div class="head-box">
    <mt-header class="book-header" :title="title">
      <div slot="left" @click.stop="onBack">
        <mt-button class="black" icon="back">返回</mt-button>
      </div>
      <slot slot="right"></slot>
    </mt-header>
  </div>
</template>

<script>
export default {
  name: "Head",
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    link:{
      type: String,
      default: ""
    }
  },
  mounted() {},
  methods: {
    onBack() {
      if(this.$store.getters.historyCount<=1){
        return this.$router.push("/");
      }
      if(this.link==""){
        this.$router.back();
      }
      else{
        this.$router.replace({ name: this.link });
      }
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.head-box {
  width: 100%;
  position: relative;
  z-index: 999;
  &::after {
    content: "";
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: scaleY(0.5);
    background: #e1e1e1;
  }
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  .book-header {
    background: #ffffff;
    .mint-header-title,
    .black {
      font-size: 13px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>