<template>
  <div class="warpper" ref="wrapper" :style="[{bottom:bottom+'px'},{top:(top=='none'?0:top+'px')},{backgroundColor:bg}]">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name:"Warpper",
  data() {
    return {};
  },
  props: {
      top:{
          type:String,
          default:"none"
      },
      bottom:{
          type:String,
          default:"0"
      },
      bg:{
          type:String,
          default:"transparent"
      }
  },
  components:{
    
  },
  created(){},
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.warpper{
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0px;
    background-color: transparent;
}
</style>