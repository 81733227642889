<template>
  <div class="container" ref="container" :style="{'top':(top=='none'?0:top+'px')}">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BScroll from '@better-scroll/core'
import Pullup from '@better-scroll/pull-up'
import MouseWheel from '@better-scroll/mouse-wheel'
BScroll.use(MouseWheel)
BScroll.use(Pullup)
export default {
  name:"Container",
  data() {
    return {
      scroll:null
    };
  },
  props: {
      probeType:{
        type:String,
        default:"1"
      },
      hasNav:{
        type:Boolean,
        default:true,
      },
      top:{
          type:String,
          default:"none"
      },
      bounceBottom:{
          type:Boolean,
          default:false
      },
  },
  components:{
    
  },
  created(){},
  mounted(){
    this.scroll = new BScroll(this.$refs.container,{
        scrollY: true,
        click: true,
        bounceTime:300,
        probeType:+this.probeType,
        mouseWheel:true,
        bounce:{
            top:false,
            bottom:this.bounceBottom,
            left:false,
            right:false
        },
        pullUpLoad: true
    })
    this.$nextTick(()=>{
      this.refresh()
    })
  },
  methods: {
    enable(){
      this.scroll&&this.scroll.enable();
    },
    disable(){
      this.scroll&&this.scroll.disable();
    },
    destroy(){
      this.scroll&&this.scroll.destroy();
    },
    refresh(){
      this.scroll&&this.scroll.refresh();
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0px;
  background-color: transparent;
  overflow: hidden;
}
</style>