import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import { isWX } from "@/plugins/untils"

// 全局引入mint-ui
import MintUI,{Toast,MessageBox,Indicator} from 'mint-ui'
import 'mint-ui/lib/style.css'

Vue.use(MintUI);
Vue.prototype.$MessageBox = MessageBox
Vue.prototype.$Indicator = Indicator
Vue.prototype.$toast = ({ message, position, duration }) => {
  Toast({
    message,
    position: position || "center",
    duration: duration || 2000
  });
}

// 样式初始化及rem适配
import 'normalize.css'
import 'assets/reset.css'
import '@/plugins/rem'

// 图片预览插件
import preview from 'vue-photo-preview' 
import 'vue-photo-preview/dist/skin.css' 
Vue.use(preview)


// 自定义插件
import Head from "components/Head"
import Warpper from "components/Warpper"
import Container from "components/Container"

Vue.component(Head.name,Head)
Vue.component(Warpper.name,Warpper)
Vue.component(Container.name,Container)


// 微信授权
import '@/permission';

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
