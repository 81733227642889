
import qs from 'qs';

/**
 * 随机生成RGB颜色
 */
export const randomRgbColor = () => {
    let r = Math.floor(Math.random() * 126) + 90;
    let g = Math.floor(Math.random() * 126) + 90;
    let b = Math.floor(Math.random() * 126) + 90;
    return `rgb(${r},${g},${b})`;
}


/**
 * 环境是否为微信
 */
export const isWX = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
    } else {
        return false;
    }
}

export const addressData = require("@/json/address.json");

// 获取 localStorage
export function getStorage(key) {
    return window.localStorage.getItem(key)
}
// 设置 localStorage
export function setStorage(key, value) {
    return window.localStorage.setItem(key, value)
}
// 删除 localStorage
export function removeStorage(key) {
    return Cookies.remove(key)
}

/**
 * 获取地址参数
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = url.split('?')[1]
    if (!search) {
        return {}
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
            .replace(/\+/g, ' ') +
        '"}'
    )
}


/**
 * 处理url链接
 * @returns {string}
 */
export function processUrl() {
    const url = window.location.href
    // 解决多次登录url添加重复的code与state问题
    const urlParams = qs.parse(url.split('?')[1])
    let redirectUrl = url
    if (urlParams.code && urlParams.state) {
      delete urlParams.code
      delete urlParams.state
      const query = qs.stringify(urlParams)
      if (query.length) {
        redirectUrl = `${url.split('?')[0]}?${query}`
      } else {
        redirectUrl = `${url.split('?')[0]}`
      }
    }
    return redirectUrl
  }
  
  export  const getQueryString = function(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}